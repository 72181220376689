import React, { useState } from 'react'
import styled from 'styled-components'
import FilterNav from '../../../components/FilterNav'
import SectionsContainer from '../../../components/SectionsContainer'

const Wrapper = styled(SectionsContainer)`
  .section {
    max-width: ${(props) => props.theme.containerWidths.medium};
  }
  .registration-iframe {
    margin-top: 24px;
  }
  .conditions {
    font-style: italic;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  .registration-info {
    max-width: 340px;
    margin: auto;
    padding-top: 24px;
  }
`

const GridTable = styled.article`
  display: grid;
  grid-template-columns: max-content auto;
  text-align: left;
  grid-gap: 10px 38px;
  margin-top: 20px;
  p {
    margin: 0;
  }
  .th {
    font-weight: 600;
  }
  ~ .headline--reunion {
    margin-top: 40px;
  }
  ul:not([class]) {
    margin: 0;
    + p {
      margin: 0;
    }
  }
  & + p {
    margin-top: 24px;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    grid-gap: 15px 80px;
  }
`

const Tickets = () => {
  const ticketOptions = {
    loveAttendees: {
      openDate: new Date(2024, 1, 3, 13, 0, 0),
      closeDate: new Date(2024, 1, 5, 23, 59, 59),
      description: 'LOVE Attendees',
      price: '$195 USD or $260 CAD*',
      includes: [
        'Access to all non-ticketed event activities ',
        '8 raffle tickets',
        'Chance to win a lunch with Cara for you and a guest** (25 winners randomly selected)',
        'Chance to win VIP seating for you and a guest during General Sessions I, III, and IV** (15 winners randomly selected)',
      ],
      //images: data.allContentfulAsset.edges,
      images: [],
      ticketUrl: 'https://www.seintofficial.com/en/office/events/over-the-rainbow/register',
    },
    earlyBird: {
      openDate: new Date(2024, 1, 6, 0, 0, 0),
      closeDate: new Date(2024, 5, 3, 23, 59, 59),
      description: 'Early Bird',
      price: '$175 USD or $230 CAD*',
      includes: [
        'Access to all non-ticketed event activities',
        '5 raffle tickets',
        'Chance to win a lunch with Cara for you and a guest** (25 winners randomly selected)',
        'Chance to win VIP seating for you and a guest during General Sessions I, III, and IV** (15 winners randomly selected)',
      ],
      images: [],
      ticketUrl: 'https://www.seintofficial.com/en/office/events/over-the-rainbow/register',
    },
    standard: {
      openDate: new Date(2024, 5, 4, 0, 0, 0),
      closeDate: new Date(2024, 7, 31, 23, 59, 59),
      description: 'Standard',
      price: '$225 USD or $295 CAD*',
      includes: [
        'Access to all non-ticketed event activities',
        '3 raffle tickets',
      ],
      images: [],
      ticketUrl: 'https://www.seintofficial.com/en/office/events/over-the-rainbow/register',
    },
  }

  const today = new Date()

  const [ticketOption, setTicketOption] = useState(
    ticketOptions.loveAttendees.closeDate > today ? 0 : ticketOptions.earlyBird.closeDate > today ? 1 : 2
  )

  const handleTicketOptions = (index) => {
    index !== ticketOption && setTicketOption(index)
  }

  const isDaylightSavingsTime = (date) => {
    const currentOffset = date.getTimezoneOffset()
    const standardOffset = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
    return currentOffset !== standardOffset
  }

  return (
    <Wrapper>
      <section className="section wrap first-section">
        <h3 className="headline--reunion center-align">Event Tickets</h3>
        <FilterNav>
          {Object.values(ticketOptions).map((option, i) => (
            <button
              key={i}
              className={i === ticketOption ? 'is-active' : null}
              onClick={() => handleTicketOptions(i)}
            >
              {option.description}
            </button>
          ))}
        </FilterNav>
        {Object.values(ticketOptions).map((option, i) => (
          <>
            <GridTable style={{ display: ticketOption === i ? 'grid' : 'none' }} className="body-content">
              <p className="th">Price</p>
              <p>{option.price}</p>
              <p className="th">Available</p>
              <p>
                {option.openDate.toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}, {option.openDate.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                })} {isDaylightSavingsTime(option.openDate) ? 'MDT' : 'MST'}{' - '}
                {option.closeDate.toLocaleString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}, {option.closeDate.toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                })} {isDaylightSavingsTime(option.closeDate) ? 'MDT' : 'MST'}
              </p>
              <p className="th">Includes</p>
              <ul>
                {option.includes.map((included, i) => (
                  <li key={i}>{included}</li>
                ))}
              </ul>
              <p></p>
              <ol className="conditions">
                <li>*Rates do not include sales tax. Please see Policies and FAQs for additional information. Tickets will be capped at 2,500 attendees.</li>
                <li>**Drawing winners will be announced in June 2024!</li>
              </ol>
            </GridTable>
            <p style={{ display: ticketOption === i ? 'block' : 'none', textAlign: 'center' }}>
              <a
                className="btn--submit"
                style={{ textDecoration: 'none' }}
                href={option.ticketUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Register for Over The Rainbow ↗
              </a>
            </p>
          </>
        ))}
      </section>
    </Wrapper >
  )
}

export default Tickets
